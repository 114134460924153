import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  .rowHight {
    min-height: 700px !important;
    background: red !important;
  }

  .btnLimiteCredito {
    float: right;
    padding: 8px 8px 8px 8px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    /* margin-bottom: 5px; */
    transition: 0.4s;
    color: #424242;
  }
  .btnLimiteCredito:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
`;

export const ContainerTable = styled.div`
  .table tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table tbody tr td {
    border-left: 1px solid #dee2e6;
  }
  .table tbody tr td input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #6b778c;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;

export const ButtonSeparator = styled.button`
  float: right;
  background-color: transparent;
  border: none;
  height: 30px;
  padding: 0 0px;
  border-radius: 5px;
  transition: 0.4s;
  color: #424242;
  &:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
`;

export const ContainerCardSingle = styled.div`
  margin: 0 auto;
  display: flex !important;
  justify-content: flex-start !important;
  .containerCards {
    @media screen and (max-width: 999px) {
      display: flex !important;
      justify-content: flex-start !important;
    }
    @media screen and (max-width: 974px) {
      display: flex !important;
      justify-content: center !important;
    }
  }

  .cardSingle {
    @media screen and (max-width: 999px) {
      width: 33% !important;
      margin-bottom: 40px !important;
    }

    @media screen and (max-width: 974px) {
      width: 40% !important;
      margin-bottom: 40px !important;
    }
    @media screen and (max-width: 720px) {
      width: 100% !important;
      margin-bottom: 40px !important;
    }
  }
`;

export const ButtonHistorico = styled.button`
  padding: 3px 12px;
  height: 40px;
  min-width: 160px;
  border-radius: 4px;
  gap: 5px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.success {
    background-color: #28a745;
    &:disabled {
      background-color: #b7e5c1;
      cursor: not-allowed;
      opacity: 0.7;
      &:hover {
        background-color: #b7e5c1;
        opacity: 1;
      }
    }
  }
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  color: #000;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #504e4e;
    box-shadow: none !important;
  }
`;

export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #ffc107;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  animation: all 0.25s ease-in;

  &.duplicate {
    background: #007bff;
    width: 300px;
  }
  &.return-search {
    background: #ffc107;
    width: 190px;
  }

  &.limpar {
    background: #ffc107;
  }

  &.cancelar {
    background: #dc3545;
  }

  &.novo {
    background: #007bff;
  }

  &.delete {
    background: #dc3545;
  }

  &.refresh {
    background-color: #9900ff;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
