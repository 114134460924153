const createColumn = (
  id: string,
  label: string,
  width: string,
  minWidth: string,
) => {
  return {
    id,
    label,
    width,
    minWidth,
    align: 'center',
    alignColumn: 'center',
  };
};

export const columns: any[] = [
  createColumn('des_preco', 'Preço', '200px', '100px'),
  createColumn('val_preco', 'Atual', '80px', '80px'),
  createColumn('val_preco_anterior', 'Anterior', '100px', '100px'),
  createColumn('calcSugVda', 'Sugestão', '90px', '90px'),
  createColumn('val_margem_ref', 'Margem Ref.', '100px', '100px'),
  createColumn('calcMargVda', 'Margem Calc.', '100px', '100px'),
  createColumn('variacao', 'Variação', '100px', '100px'),
  createColumn('dta_alteracao', 'Data Reajuste', '150px', '150px'),
];
