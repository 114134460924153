import React from 'react';
import { HistoricoProdutoContextProvider } from './HistoricoProdutoContext';
import MemoContent from './HistoricoProdutoContent';

interface HistoryProps {
  codProduto: number | string;
  codLoja: number;
  hasIcone?: boolean;
  user?: any;
}

export const HistoricoProduto: React.FC<HistoryProps> = (props) => {
  const { codProduto, codLoja, hasIcone = false, user } = props;

  return (
    <HistoricoProdutoContextProvider user={user}>
      <MemoContent
        codProduto={codProduto}
        codLoja={codLoja}
        hasIcone={hasIcone}
      />
    </HistoricoProdutoContextProvider>
  );
};
