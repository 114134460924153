import { ProdutoProps } from '../protocols';

export const produtoDefault: ProdutoProps = {
  cod_loja: -1,
  cod_produto: '',
  des_cidade: '',
  des_loja: '',
  des_produto: '',
  des_uf: '',
  dta_ult_alt_custo_rep: '',
  dta_ult_mov_venda: '',
  lista_movimentos: [],
  lista_precos: [],
  qtd_estoque: 0,
  qtd_venda_media: 0,
  val_custo_cheio: 0,
  val_custo_com_imposto: 0,
  val_custo_medio: 0,
  val_custo_pmz: 0,
  val_custo_rep: 0,
  val_custo_rep_ant: 0,
  val_custo_sem_imposto: 0,
  val_lucro_bruto: 0,
  val_imposto_debito: 0,
  per_desp_op: 0,
  per_icms_saida: 0,
  tipo_margem: 0,
  per_fcp: 0,
  per_pis_lj: 0,
  per_cofins_lj: 0,
};
